import React, { useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
// import { Container } from "reactstrap";
import { GlobalState } from "../../../Data/Context";
import HWlogo from "../../../Assets/Group 40.png";
import PalmPayLogo from "../../../Assets/palmpaylogo.png"

const ManageWallet = () => {
  let { setStateName, findProviderFunding } = useContext(GlobalState);
  useEffect(() => {
    setStateName("Manage Provider Funding");
    findProviderFunding({ provider: "monnify" });
    findProviderFunding({ provider: "paystack" });
    findProviderFunding({ provider: "flutterwave" });
    findProviderFunding({ provider: "budpay" });
    findProviderFunding({ provider: "transfer" });
    findProviderFunding({ provider: "palmpay" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  let navigate = useNavigate(),
    params = useParams(),
    controlsTab = [
      {
        name: "Monnify",
        type: "link",
        link: `/${params?.page}/${params?.id}/monnify`,
        color: "linear-gradient(90deg, #DE0DE2 16.14%, #0E102D 101.45%)",
        textColor: "white",
        url: "https://monnify.com/assets/img/svg/site-logo.svg",
      },
      {
        name: "Flutterwave",
        type: "link",
        link: `/${params?.page}/${params?.id}/flutterwave`,
        color: "linear-gradient(90deg, #F45F83 16.14%, #9E1A2A 101.45%)",
        textColor: "white",
        url: "https://upload.wikimedia.org/wikipedia/commons/thumb/9/9e/Flutterwave_Logo.png/1200px-Flutterwave_Logo.png?20220812092224",
      },
      {
        name: "Paystack",
        type: "link",
        link: `/${params?.page}/${params?.id}/paystack`,
        color: "linear-gradient(96.86deg, #F2E553 18.88%, #FF9900 125.77%)",
        url: "https://upload.wikimedia.org/wikipedia/commons/thumb/0/0b/Paystack_Logo.png/1200px-Paystack_Logo.png?20200430170057",
      },
      {
        name: "Manual",
        type: "link",
        link: `/${params?.page}/${params?.id}/manual`,
        color: "linear-gradient(90.18deg, #3199B7 -52.19%, #144468 81.92%)",
        textColor: "white",
        url: HWlogo,
      },
      {
        name: "Budpay",
        type: "link",
        link: `/${params?.page}/${params?.id}/budpay`,
        color:
          "linear-gradient(96.86deg, rgba(83, 242, 147, 0.8) 18.88%, rgba(158, 255, 0, 0.8) 125.77%)",
        url: "https://merchant.budpay.com/assets/front/img/BudPay-Logo3.png",
      },
      {
        name: "Wallet to Wallet Transfer",
        type: "link",
        link: `/${params?.page}/${params?.id}/transfer`,
        color: "linear-gradient(90.18deg, #3199B7 -52.19%, #144468 81.92%)",
        textColor: "white",
        url: HWlogo,
        usecase: "transfer",
      },

	  {
        name: "PalmPay",
        type: "link",
        link: `/${params?.page}/${params?.id}/palmpay`,
        color: "linear-gradient(90.18deg, #3199B7 -52.19%, #144468 81.92%)",
        textColor: "white",
        url: PalmPayLogo,
        // usecase: "transfer",
      },
      // {
      // 	name: "Data profit",
      // 	type: "link",
      // 	link: `/${params?.page}/${params?.id}/bonus-data`,
      // 	color:
      // 		"linear-gradient(90deg, rgba(228, 51, 105, 0.7) 16.14%, rgba(194, 14, 25, 0.7) 101.45%)",
      // 	textColor: "white",
      // },
      // {
      // 	name: "give bonus",
      // 	type: "link",
      // 	link: `/${params?.page}/${params?.id}/give-bonus`,
      // 	color: "linear-gradient(90.18deg, #6CB731 -52.19%, #0F5A16 81.92%)",
      // 	textColor: "white",
      // },
      // {
      // 	name: "manual top up",
      // 	type: "link",
      // 	link: `/${params?.page}/${params?.id}/manual`,
      // 	color: "linear-gradient(90deg, #E43369 16.14%, #C20E19 101.45%)",
      // 	textColor: "white",
      // },
      // {
      // 	textColor: "white",
      // 	name: "direct debit",
      // 	type: "button",
      // 	link: `debit`,
      // 	color: "linear-gradient(90.18deg, #3199B7 -52.19%, #144468 81.92%)",
      // },
      // {
      // 	name: "Wallet  funding",
      // 	type: "link",
      // 	link: `/${params?.page}/${params?.id}/wallet-funding`,
      // 	color: "linear-gradient(96.86deg, #F2E553 18.88%, #FF9900 125.77%)",
      // },
    ],
    { auth, permission } = useContext(GlobalState);

  useEffect(() => {
    if (auth?.user?.privilege !== "superadmin") {
      let findTitle = "viewwalletfunding";

      let findPermit = permission?.data?.find(
        (item) =>
          item?.slug === findTitle && item?.user?.includes(auth?.user?._id)
      );
      if (!findPermit) {
        navigate(-1);
      }
    }
  }, [auth?.user, permission?.data, navigate]);

  return (
    <div className="bg-white aboutScreen">
      <div className="tw-px-5 md:tw-px-10">
        <div className="tw-grid tw-w-11/12 tw-gap-10  tw-mx-auto md:tw-grid-cols-2 lg:tw-grid-cols-3 tw-py-16">
          {controlsTab?.map((item, i) => (
            <div
              onClick={() => {
                if (item?.type === "link") {
                  navigate(item?.link);
                } else if (item?.type === "button") {
                  // if (item?.link === "debit") toggle();
                }
              }}
              className="tw-flex tw-justify-between tw-px-6 tw-h-48 2xl:tw-h-72 tw-shadow-md tw-items-center myCursor"
              key={i}
            >
              <div className="Lexend">
                <img
                  src={item.url}
                  alt=""
                  className="tw-h-16  img-fluid objectFit w-100"
                />
                {item?.usecase ? (
                  <span className="Lexend tw-font-semibold text-center">
                    {item?.name}
                  </span>
                ) : null}
              </div>
              {/* <span
								className="d-flex align-items-center justify-content-center text2  text-decoration-none h-100 eachProduct fontReduceBig textTrunc p-2 p-md-0 py-3 py-md-0 h-100"
								// style={{
								//   background: item?.color,
								//   borderRadius: "30px",
								//   color: item?.textColor ? item?.textColor : "#000",
								// }}
							>
								<span className="tw-text-xl tw-text-[#1b1b1b] tw-font-medium text-capitalize">
									{item?.name}
								</span>
							</span> */}
            </div>
          ))}
        </div>
        {/* <div className="row mx-0 g-2 g-md-4 py-4 py-md-5">
					{controlsTab?.map((item, i) => (
						<div className="col-6 col-md-4 productCard" key={i}>
							<span
								onClick={() => {
									if (item?.type === "link") {
										navigate(item?.link);
									}
								}}
								className="d-flex align-items-center justify-content-center text2 myCursor text-decoration-none h-100 eachProduct fontReduceBig textTrunc p-2 p-md-0 py-3 py-md-0 h-100"
								style={{
									background: item?.color,
									borderRadius: "30px",
									color: item?.textColor ? item?.textColor : "#000",
								}}>
								<span className="textTrunc fontInherit">{item?.name}</span>
							</span>
						</div>
					))}
				</div> */}
      </div>
    </div>
  );
};

export default ManageWallet;
