import React, { useContext, useEffect } from "react";
import { GlobalState } from "../../Data/Context";
import { useNavigate, useParams } from "react-router-dom";
// import { Container } from "reactstrap";
import { Icon } from "@iconify/react";

const colors = ["#f1c40f", "#fd7e14", "#0d6efd", "#d63384", "#198754"];

const VerifcationType = () => {
  let { setStateName } = useContext(GlobalState);

  useEffect(() => {
    setStateName("Verification Type");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  let navigate = useNavigate(),
    params = useParams(),
    controlsTab = [
      {
        name: "Business Verification",
        type: "link",
        link: `/${params?.page}/biz/verify`,
        color: "linear-gradient(96.86deg, #F2E553 18.88%, #FF9900 125.77%)",
        icon: "fluent-mdl2:home-verify",
      },
      {
        name: "NIN Verification",
        type: "link",
        link: `/${params?.page}/verification/verify`,
        icon: "tdesign:verify",
        color:
          "linear-gradient(96.86deg, rgba(83, 242, 147, 0.8) 18.88%, rgba(158, 255, 0, 0.8) 125.77%)",
      },
    ];

  return (
    <div className="bg-white aboutScreen">
      <div className="tw-px-5 md:tw-px-10">
        <div className="tw-grid tw-w-11/12 tw-gap-10  tw-mx-auto md:tw-grid-cols-2 lg:tw-grid-cols-3 tw-py-16">
          {controlsTab?.map((item, i) => (
            <div
              onClick={() => {
                if (item?.type === "link") {
                  navigate(item?.link);
                } else if (item?.type === "button") {
                }
              }}
              className="tw-flex tw-justify-between tw-px-6 tw-h-20 tw-shadow-md tw-items-center myCursor"
              key={i}
            >
              <div>
                <Icon
                  color={colors[i % colors.length]}
                  style={{
                    fontSize: "36px",
                  }}
                  icon={item?.icon}
                />
              </div>
              <span
                className="d-flex align-items-center justify-content-center text2  text-decoration-none h-100 eachProduct fontReduceBig textTrunc p-2 p-md-0 py-3 py-md-0 h-100"
                // style={{
                //   background: item?.color,
                //   borderRadius: "30px",
                //   color: item?.textColor ? item?.textColor : "#000",
                // }}
              >
                <span className="tw-text-xl tw-text-[#1b1b1b] tw-font-medium text-capitalize">
                  {item?.name}
                </span>
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default VerifcationType;
