import React, { useContext, useEffect, useState } from "react";
import { GlobalState } from "../../../Data/Context";
// import { Container } from "reactstrap";
import { BonusCommission } from "../../../Components/Wallets";
import LoadMore, { BottomTab } from "../../../Components/LoadMore";
import moment from "moment";
import { Buttons, EmptyComponent } from "../../../Utils";
import { MainPaginate, MainRanger } from "../../../Components/Transactions";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { BiCopy } from "react-icons/bi";
import { ExportButtons2 } from "../../../Utils/Export Functions/ExportButtons";

const Referral = () => {
  let { setStateName, wallet } = useContext(GlobalState),
    [active, setActive] = useState(0),
    navigate = useNavigate(),
    { page, id } = useParams();

  useEffect(() => {
    setStateName("Referral history");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="bg-white aboutScreen">
      <div className="py-3 py-md-5 tw-px-5 md:tw-px-10">
        <h5 className="Lexend">Referral History</h5>
        <small className="text2 Lexend tw-flex tw-items-center tw-gap-2">
          Referral Code:
          <span className="tw-text-md tw-font-semibold tw-flex tw-items-center tw-gap-4 tw-p-2 tw-border tw-rounded-md tw-w-fit tw-border-black">
            {wallet?.balance?.wallet_id}{" "}
            <span
              className="mt-auto myCursor force-d-flex tw-px-2 tw-border-l tw-border-black"
              onClick={
                wallet?.balance?.wallet_id
                  ? () => {
                      navigator.clipboard
                        .writeText(wallet?.balance?.wallet_id)
                        .then(
                          () => {
                            toast.info("Copied", { autoClose: 2000 });
                          },
                          (err) => {
                            toast.warn(`Could not copy: ${err}`, {
                              autoClose: 2000,
                            });
                          }
                        );
                    }
                  : null
              }
            >
              <BiCopy />
            </span>
          </span>
        </small>
        <Buttons
          title={"All Referred User"}
          css="btn-primary1 text-capitalize py-3 px-4 px-lg-5 my-3 ms-auto"
          width={"w-25 w25"}
          onClick={() => navigate(`/${page}/${id}/all-referral`)}
          style={{ borderRadius: "30px" }}
        />
        <div className="btn-group w-100 py-3">
          <button
            className={`btn py-3 text-capitalize fw-bold ${
              active === 0 ? "border-bottom textColor" : ""
            } rounded-0`}
            onClick={() => setActive(0)}
          >
            Referral Bonus
          </button>
          <button
            className={`btn py-3 text-capitalize fw-bold ${
              active === 1 ? "border-bottom textColor" : ""
            } rounded-0`}
            onClick={() => setActive(1)}
          >
            Referred User <span className="text-lowercase">(s)</span>
          </button>
        </div>
        {active === 1 ? <ReferedUser /> : <BonusCommission type={"referral"} />}{" "}
      </div>{" "}
    </div>
  );
};

export default Referral;

const ReferedUser = () => {
  const { getReferrals, referral, getReload } = useContext(GlobalState);

  let [state, setState] = useState(null),
    [search, setSearch] = useState(null);

  useEffect(() => {
    getReferrals();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getReload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let [loading, setLoading] = useState(false);
  let handleLoadMore = async () => {
    setLoading(true);

    await getReferrals({
      page: Number(
        search
          ? referral?.search_paginate?.nextPage
          : referral?.general_paginateAll?.nextPage
      ),
      // limit: Number(
      // 	search
      // 		? referral?.search_paginate?.nextPage *
      // 				referral?.search_paginate?.limit
      // 		: referral?.general_paginateAll?.nextPage *
      // 				referral?.general_paginateAll?.limit
      // ),
      search,
    });
    setLoading(false);
  };

  useEffect(() => {
    if (referral?.isFound) setState(referral?.mainSearch);
    else setState(referral?.general_referralAll);
  }, [referral?.isFound, referral?.mainSearch, referral?.general_referralAll]);

  useEffect(() => {
    if (search) {
      document.getElementById("Search").addEventListener("search", () => {
        getReload();
      });
      let handleSubmit = async () => {
        if (!search) return;

        await getReferrals({
          search,
        });
      };
      handleSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  let [range, setRange] = useState(10);

  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + range;
  if (!state) return;

  const currentItems = state.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(state.length / range);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * range) % state.length;
    setItemOffset(newOffset);
  };

  return (
    <div className="py-5">
      <div className="w-50 w50 mb-3">
        <input
          type="search"
          name="search"
          id="Search"
          className="form-control w-100 py-3 borderColor2"
          placeholder="Type here to search"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
      <div className="tw-flex tw-justify-between tw-items-center">
        <div className="tw-flex-1">
          <MainRanger range={range} setRange={setRange} />
        </div>
        <ExportButtons2
          tableHeadings={["S/N", "Referee", "User", "Data & Time"]}
          currentItems={currentItems}
          fileName="Referred-User(s)"
        />
      </div>
      <div className="bland row mx-0 py-3 px-0 text-capitalize">
        <div className="col textTrunc fontReduce fw-bold Lexend">S/N</div>
        <div className="col textTrunc fontReduce fw-bold Lexend">Referee</div>
        <div className="col textTrunc fontReduce fw-bold Lexend">User</div>
        <div className="col textTrunc fontReduce fw-bold Lexend">
          Data & Time
        </div>
      </div>
      <div className="bland2 row mx-0">
        {currentItems?.length === 0 ? (
          <EmptyComponent subtitle={`Referral list is empty`} />
        ) : (
          currentItems?.map((item, index) => (
            <div key={index} className="row mx-0 py-3 px-0">
              <div className="col textTrunc fontReduce2 my-auto textTrunc textTrunc3">
                {index + 1}
              </div>
              <div className="col textTrunc fontReduce2 my-auto">
                {item?.referee?.firstName} {item?.referee?.lastName}
              </div>
              <div className="col textTrunc fontReduce2 my-auto">
                {item?.user?.firstName} {item?.user?.lastName}
              </div>
              <div className="col textTrunc fontReduce2 my-auto">
                {moment(item?.createdAt).format("DD/MM/YYYY hh:mm A")}
              </div>
            </div>
          ))
        )}
      </div>
      <MainPaginate handlePageClick={handlePageClick} pageCount={pageCount} />
      <BottomTab state={state} paginate={referral?.general_paginateAll} />
      <LoadMore
        next={referral?.general_paginateAll?.next}
        handleLoadMore={handleLoadMore}
        loading={loading}
      />
    </div>
  );
};
