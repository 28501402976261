import React, { useContext, useEffect, useState } from "react";
import { GlobalState } from "../../Data/Context";
// import { Container } from "reactstrap";
import { MainTransFinder } from "../wallets/find-transactions";

const UserRegistration = () => {
  let { setStateName, stat, getDataTransactionStat } = useContext(GlobalState);
  useEffect(() => {
    setStateName("User Registration Stat");
    getDataTransactionStat({ timeframe: "daily" }, "reg");
    localStorage.removeItem("Year");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let [userStat, setUserStat] = useState(null);

  useEffect(() => {
    setUserStat(stat?.registration?.userStat);
    console.log(stat);
  }, [stat?.registration]);

  return (
    <div className="bg-white aboutScreen">
      <div className="py-3 py-md-5 tw-px-5 md:tw-px-10">
        <MainTransFinder
          notype
          // dataEnd
          hideData={"user"}
          pageStat={userStat}
        />
      </div>{" "}
    </div>
  );
};

export default UserRegistration;
