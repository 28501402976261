import React, { useContext, useEffect, useState } from "react";
// import { Container } from "reactstrap";
import { ModalComponents } from "../Components";
import { GlobalState } from "../Data/Context";
import { Buttons } from "../Utils";
import { useNavigate, useParams } from "react-router-dom";
import { MainPaginate, MainRanger } from "../Components/Transactions";
import { TransactionImage } from "../Components/Products/airtime";
import { ExportButtons2 } from "../Utils/Export Functions/ExportButtons";

const Switchers = () => {
  let { setStateName } = useContext(GlobalState);
  useEffect(() => {
    setStateName("Switchers");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let navigate = useNavigate();
  return (
    <div className="bg-white aboutScreen">
      <div className="py-5 tw-px-5 md:tw-px-10">
        <div className="d-flex justify-content-end">
          <Buttons
            title={"view history"}
            css="btn-primary1 text-capitalize py-3 my-4 px-5 mx-2"
            width={"w-auto"}
            onClick={() => {
              navigate("/inventory");
            }}
            style={{ borderRadius: "30px" }}
          />
        </div>
        <BillersDetails />
      </div>{" "}
    </div>
  );
};

export default Switchers;

const BillersDetails = () => {
  let { category, manageCategory, getCategory, getReload } =
    useContext(GlobalState);

  let [state, setState] = useState(null),
    [select, setSelect] = useState(null),
    [newBiller, setNewBiller] = useState(null),
    [submit, setSubmit] = useState(false),
    [search, setSearch] = useState(""),
    [loading, setLoading] = useState(false),
    [range, setRange] = useState(10);

  const params = useParams();

  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + range;

  const handlePageClick = (event) => {
    const newOffset = (event.selected * range) % state.length;
    setItemOffset(newOffset);
  };

  useEffect(() => {
    if (category?.isFound) setState(category?.mainSearch);
    else setState(category?.data);
  }, [category?.mainSearch, category?.isFound, category?.data]);

  let [isOpen, setIsOpen] = useState(false),
    toggle = () => {
      if (isOpen) setSelect(null);
      setIsOpen(!isOpen);
    };

  useEffect(() => {
    if (submit && category?.isUpdated) {
      toggle();
      setSubmit(false);
      setNewBiller("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category?.isUpdated, submit]);

  let handleSubmit = async () => {
    if (!newBiller) return;
    setLoading(true);
    await manageCategory(
      {
        category: select?._id,
        biller: newBiller,
      },
      "switch-biller"
    );
    setLoading(false);
    setSubmit(true);
  };

  useEffect(() => {
    if (search) {
      document.getElementById("Search").addEventListener("search", () => {
        getReload();
      });
      let handleSubmit = async () => {
        if (!search) return;

        await getCategory({
          search,
        });
      };
      handleSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  if (!state) return;
  const pageCount = Math.ceil(state.length / range);

  const currentItems = state.slice(itemOffset, endOffset);

  const headers = [
    { title: "Category Name", field: "category_name" },
    { title: "Category Product", field: "category_product" },
    { title: "Current Biller", field: "current_biller" },
  ];

  const data = currentItems.map((item, index) => ({
    category_name: `${item?.categoryId}`,
    category_product: item?.product?.name ? `${item?.product?.name}` : "",
    current_biller: item?.biller?.name ? `${item?.biller?.name}` : "",
  }));

  return (
    <>
      <div className="py-5">
        <div className="w-50 w50 mb-3">
          <input
            type="search"
            name="search"
            id="Search"
            className="form-control w-100 py-3 borderColor2"
            placeholder="Type here to search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        <div className="tw-flex tw-justify-between tw-items-center">
          <div className="tw-flex-1">
            <MainRanger range={range} setRange={setRange} />
          </div>
          <ExportButtons2
            headers={headers}
            data={data}
            fileName={`${params?.page} ${params?.id ? `(${params?.id})` : ""}`}
          />
        </div>
        ;
        <div className="bland row mx-0 p-3 text-capitalize">
          <div className="col Lexend fw-bold">category name</div>
          <div className="col Lexend fw-bold">category product</div>
          <div className="col Lexend fw-bold">current biller</div>
          <div className="col Lexend fw-bold">action </div>
        </div>
        <SwtichState
          state={currentItems}
          setSelect={setSelect}
          toggle={toggle}
        />
        <MainPaginate handlePageClick={handlePageClick} pageCount={pageCount} />
      </div>
      <ModalComponents title="choose new biller" isOpen={isOpen} back={toggle}>
        <div className="downH2 d-flex">
          <form className="w-100" onSubmit={handleSubmit}>
            {select?.billerList?.map((item, i) => (
              <Buttons
                key={i}
                title={item?.name}
                css={`
                  ${newBiller === item?._id
                    ? "btn-primary1"
                    : "btn-outline-primary1"} text-uppercase py-3 px-4 px-lg-5 mx-auto my-4
                `}
                type="button"
                width={"w-50 w50"}
                style={{ borderRadius: "30px" }}
                onClick={() => {
                  setNewBiller(item?._id);
                }}
              />
            ))}
            <Buttons
              title={"switch"}
              type="button"
              css="btn-primary1 text-capitalize py-3 px-4 px-lg-5 mx-auto my-4"
              width={"w-50 w50"}
              onClick={handleSubmit}
              loading={loading}
              style={{ borderRadius: "30px" }}
            />
          </form>
        </div>
      </ModalComponents>
    </>
  );
};

export let NewSwitchPaginate = ({ state }) => {
  return <></>;
};

let SwtichState = ({ state, setSelect, toggle }) => {
  return (
    <>
      <div className="bg-white row mx-0">
        {state?.map((item, index) => (
          <div key={index} className="row mx-0 p-3 border-bottom">
            <div className="col my-auto">
              <TransactionImage
                value={
                  item?.product?.name?.includes("data")
                    ? item?.categoryId
                    : null
                }
                item={item?.categoryId}
                type={item?.product?.name?.toLowerCase()}
              />
              {/* {item?.name} */}
            </div>
            <div className="col my-auto text-uppercase">
              {item?.product?.name}
            </div>
            <div className="col my-auto text-uppercase">
              {item?.biller?.name}
            </div>
            <div className="col my-auto">
              <p
                className="text-primary text-capitalize ms-2 myCursor"
                onClick={() => {
                  setSelect(item);
                  toggle();
                }}
              >
                switch biller
              </p>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};
