import React, { useContext, useEffect, useState } from "react";
import { GlobalState } from "../../../Data/Context";
// import { Container } from "reactstrap";
import { BonusCommission } from "../../../Components/Wallets";
import { TransactionToppers } from "../../../Components/Transactions";

const Commissions = () => {
  let { setStateName } = useContext(GlobalState),
    [active, setActive] = useState(0),
    tabControl = ["My Commission history", "general commission history"];

  useEffect(() => {
    setStateName(tabControl[active]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active]);

  return (
    <div className="bg-white aboutScreen">
      <div className="py-3 py-md-5 tw-px-5 md:tw-px-10">
        <div className="d-flex items-center justify-content-between">
          <h5 className="Lexend text-capitalize">{tabControl[active]}</h5>
        </div>
        <TransactionToppers
          active={active}
          setActive={setActive}
          title={"commission"}
        />
        <BonusCommission general={active === 1} />
      </div>{" "}
    </div>
  );
};

export default Commissions;
