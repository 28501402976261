import React, { useState, useContext, useEffect } from "react";
// import { Container } from "reactstrap";
import {
  MainPaginate,
  TransactionDetails,
  TransactionsData,
} from "../../Components/Transactions";
import { GlobalState } from "../../Data/Context";
import { BottomTab } from "../../Components/LoadMore";
import { FindDetails } from "../wallets/find-transactions";
import { Buttons } from "../../Utils";

const FailedTransactions = () => {
  let { setStateName } = useContext(GlobalState);
  useEffect(() => {
    setStateName("Failed Transactions");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="bg-white">
      <div className="tw-px-5 md:tw-px-10">
        <SubFailedTransactionsFolder />
      </div>
    </div>
  );
};

export default FailedTransactions;

export const SubFailedTransactionsFolder = () => {
  const { failedTransactions, getServicesHistoryFailed, getReload } =
    useContext(GlobalState);

  // let [loading, setLoading] = useState(false),
  let [search, setSearch] = useState(""),
    [state, setState] = useState(null),
    [thisData, setThisData] = useState(null);
  const [pageCount, setPageCount] = useState(0);

  useEffect(() => {
    getServicesHistoryFailed();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (search) {
      document.getElementById("Search").addEventListener("search", () => {
        getReload();
      });
      let handleSubmit = async () => {
        if (!search) return;

        await getServicesHistoryFailed({
          search,
          filter: `${
            stateFilter?.category ? `&category=${stateFilter?.category}` : ""
          }${stateFilter?.network ? `&network=${stateFilter?.network}` : ""}${
            stateFilter?.status ? `&status=${stateFilter?.status}` : ""
          }${stateFilter?.type ? `&caseType=${stateFilter?.type}` : ""}${
            stateFilter?.startDate ? `&startDate=${stateFilter?.startDate}` : ""
          }${stateFilter?.endDate ? `&endDate=${stateFilter?.endDate}` : ""}${
            stateFilter?.channel ? `&channel=${stateFilter?.channel}` : ""
          }`,
        });
      };
      handleSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    if (failedTransactions?.isFound) {
      setState(failedTransactions?.mainSearch);
    } else setState(failedTransactions?.transactions);
    // console.log(failedTransactions);
  }, [
    failedTransactions?.transactions,
    failedTransactions?.isFound,
    failedTransactions?.mainSearch,
  ]);

  useEffect(() => {
    getReload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (failedTransactions?.search_paginate) {
      setPageCount(
        failedTransactions?.search_paginate?.total /
          failedTransactions?.search_paginate?.limit
      );
    } else {
      setPageCount(
        Math.ceil(
          failedTransactions?.paginate?.total /
            failedTransactions?.paginate?.limit
        )
      );
    }
  }, [failedTransactions?.paginate, failedTransactions?.search_paginate]);

  let init = { category: "", network: "", type: "", status: "" },
    [stateFilter, setStateFilter] = useState(init),
    [isOpen, setIsOpen] = useState(false),
    toggle = () => {
      // if (isOpen) setStateFilter(init);
      setIsOpen(!isOpen);
    },
    [loadingFilter, setLoadingFilter] = useState(false),
    [submit, setSubmit] = useState(false),
    textChange =
      (name) =>
      ({ target: { value } }) => {
        setStateFilter({ ...stateFilter, [name]: value });
      },
    handleFind = async () => {
      setLoadingFilter(true);
      await getServicesHistoryFailed({
        search,
        filter: `${
          stateFilter?.category ? `&category=${stateFilter?.category}` : ""
        }${stateFilter?.network ? `&network=${stateFilter?.network}` : ""}${
          stateFilter?.status ? `&status=${stateFilter?.status}` : ""
        }${stateFilter?.type ? `&caseType=${stateFilter?.type}` : ""}${
          stateFilter?.startDate ? `&startDate=${stateFilter?.startDate}` : ""
        }${stateFilter?.endDate ? `&endDate=${stateFilter?.endDate}` : ""}${
          stateFilter?.channel ? `&channel=${stateFilter?.channel}` : ""
        }`,
      });
      setLoadingFilter(false);
      setSubmit(true);
    };

  useEffect(() => {
    if (submit && failedTransactions?.isFound) {
      setSubmit(false);
      toggle();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submit, failedTransactions?.isFound]);

  if (!state) return <></>;

  const currentItems = state;

  const handlePageClick = async (event) => {
    if (search) {
      await getServicesHistoryFailed({
        page: Number(event?.selected + 1),
        search,
      });
    } else {
      await getServicesHistoryFailed({
        page: Number(event?.selected + 1),
      });
    }
  };

  return (
    <div className="py-5">
      <div className="w-50 w50">
        <input
          type="search"
          name="search"
          id="Search"
          className="form-control w-100 py-3 borderColor2"
          placeholder="Type here to search"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>

      <div className="tw-mt-6">
        <Buttons
          title={"filter"}
          css="btn-primary1 text-capitalize py-3 px-4 px-lg-5 mb-3"
          width={"w-25 w25"}
          onClick={toggle}
          style={{ borderRadius: "30px" }}
        />
      </div>

      <TransactionsData
        state={currentItems}
        setThisData={setThisData}
        setState={setState}
        type={"all"}
        criteria={{
          limit: failedTransactions?.paginate?.limit,
          search: search ? search : "",
        }}
        mainState={state}
      />
      <MainPaginate handlePageClick={handlePageClick} pageCount={pageCount} />
      <BottomTab
        state={state}
        paginate={
          search
            ? failedTransactions?.search_paginate
            : failedTransactions?.paginate
        }
      />
      <TransactionDetails
        type={"all"}
        criteria={{
          limit: failedTransactions?.paginate?.limit,
          search: search ? search : "",
        }}
        thisData={thisData}
        setThisData={setThisData}
      />
      <FindDetails
        isOpen={isOpen}
        back={toggle}
        state={stateFilter}
        textChange={textChange}
        handleFind={handleFind}
        loading={loadingFilter}
        filter
      />
    </div>
  );
};
