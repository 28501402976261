import React, { useState, useContext, useEffect } from "react";
// import { Container } from "reactstrap";
import { Buttons } from "../../Utils";
import { GlobalState } from "../../Data/Context";
import { useNavigate } from "react-router-dom";
import { MakeWallet, ManualHistory } from "./manual";

const Bonus = () => {
	let [isWallet, setIsWallet] = useState(false),
		toggleWallet = () => {
			setIsWallet(!isWallet);
		};

	let { setStateName } = useContext(GlobalState),
		navigate = useNavigate(),
		{ auth, permission } = useContext(GlobalState);

	useEffect(() => {
		if (auth?.user?.privilege !== "superadmin") {
			let findTitle = "viewmanualtop";

			let findPermit = permission?.data?.find(
				item =>
					item?.slug === findTitle && item?.user?.includes(auth?.user?._id)
			);
			if (!findPermit) {
				navigate(-1);
			}
		}
	}, [auth?.user, permission?.data, navigate]);
	useEffect(() => {
		setStateName("manual wallet debit history");
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<div className="bg-white aboutScreen">
				<div className="py-5 tw-px-5 md:tw-px-10">
					<Buttons
						title={"Debit user"}
						css="btn-primary1 text-capitalize py-3 px-4 px-lg-5"
						width={"w-25 w25"}
						onClick={toggleWallet}
						style={{ borderRadius: "30px" }}
					/>
					<ManualHistory type={"debit"} />
				</div>
			</div>
			<MakeWallet isOpen={isWallet} back={toggleWallet} debit />
		</>
	);
};

export default Bonus;
