import React, { useContext, useEffect, useState } from "react";
import { BsPen } from "react-icons/bs";
import { useNavigate, useParams } from "react-router-dom";
// import { Container } from "reactstrap";
import { ModalComponents } from "../../../Components/DefaultHeader";
import { GlobalState } from "../../../Data/Context";
import { Buttons } from "../../../Utils";
import { BillerSelectPlan } from ".";
import { NumericFormat } from "react-number-format";

const DataPlanPriceList = () => {
  let { dataBundle, nairaSign, numberWithCommas } = useContext(GlobalState),
    [state, setState] = useState(null),
    [isOpen, setIsOpen] = useState(null),
    toggle = () => {
      if (isOpen) if (data) setData(null);
      setIsOpen(!isOpen);
    },
    [data, setData] = useState(null),
    [others, setOthers] = useState(null),
    { step } = useParams(),
    [isOpenInit, setIsOpenInit] = useState(false),
    toggleInit = () => {
      setIsOpenInit(!isOpenInit);
    },
    navigate = useNavigate(),
    { auth, permission } = useContext(GlobalState);

  useEffect(() => {
    if (auth?.user?.privilege !== "superadmin") {
      let findTitle = "viewmanagedataplans";

      let findPermit = permission?.data?.find(
        (item) =>
          item?.slug === findTitle && item?.user?.includes(auth?.user?._id)
      );
      if (!findPermit) {
        navigate(-1);
      }
    }
  }, [auth?.user, permission?.data, navigate]);

  useEffect(() => {
    if (data) setIsOpen(true);
  }, [data]);

  useEffect(() => {
    if (dataBundle?.isFound)
      setState(dataBundle?.mainSearch?.find((item) => item?._id === step));
    else setState(dataBundle?.data?.find((item) => item?._id === step));
  }, [dataBundle?.mainSearch, dataBundle?.isFound, dataBundle?.data, step]);

  if (!state) return;
  return (
    <>
      <div className="bg-white aboutScreen">
        <div className="py-5 tw-px-5 md:tw-px-10">
          <h3 className="Lexend mb-3">
            {state?.category?.name} - {state?.validity} {state?.allowance}
            {state?.size}
          </h3>
          <div className="py-3 d-flex justify-content-end">
            <Buttons
              title={"add new biller"}
              css="btn-primary1 text-capitalize py-md-3 py-2 px-4 px-lg-5 my-md-4 my-2 mb-0"
              width={"w-auto"}
              onClick={toggleInit}
              style={{ borderRadius: "30px" }}
            />
          </div>
          <div className="bland row mx-0 py-3 px-0 text-capitalize Lexend fw-bold">
            <div className="col textTrunc Lexend">Provider price</div>
            <div className="col textTrunc Lexend">price</div>
            <div className="col textTrunc Lexend">reseller</div>
            <div className="col textTrunc Lexend">agent</div>
            {/* <div className="col textTrunc Lexend">topuser</div> */}
            <div className="col textTrunc Lexend">action </div>
          </div>
          {state?.billerList?.map((item, i) => (
            <div className="" key={i}>
              <h5 className="Lexend text-uppercase">{item?.biller?.name}</h5>
              {[item?.price]?.map((list, index) => (
                <div key={index} className="row mx-0 py-3 px-0 border-bottom">
                  <div className="col d-none d-md-flex textTrunc my-auto">
                    {nairaSign}
                    {list?.provider_price
                      ? numberWithCommas(
                          Number(list?.provider_price).toFixed(2)
                        )
                      : 0}
                  </div>
                  <div className="col d-none d-md-flex textTrunc my-auto">
                    {nairaSign}
                    {list?.price
                      ? numberWithCommas(Number(list?.price).toFixed(2))
                      : 0}
                  </div>
                  <div className="col textTrunc my-auto">
                    {nairaSign}
                    {list?.resellerPrice
                      ? numberWithCommas(Number(list?.resellerPrice).toFixed(2))
                      : 0}
                  </div>
                  <div className="col textTrunc my-auto">
                    {nairaSign}
                    {list?.agentPrice
                      ? numberWithCommas(Number(list?.agentPrice).toFixed(2))
                      : 0}
                  </div>
                  {/* <div className="col textTrunc my-auto">
										{nairaSign}
										{list?.topuserPrice
											? numberWithCommas(Number(list?.topuserPrice).toFixed(2))
											: 0}
									</div> */}
                  <div className="col textTrunc my-auto">
                    <button
                      title="Edit Data plan"
                      onClick={() => {
                        setData(list);
                        setOthers(item);
                      }}
                      className="btn btn-success2 text-capitalize p-1 p-md-2 w-100 fontReduce2"
                    >
                      <BsPen />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
      <MakeDataBundle
        isOpen={isOpen}
        toggle={toggle}
        back={() => {
          toggle();
          toggleInit();
        }}
        datum={data}
        others={others}
      />
      <BillerCategorySelection
        isOpen={isOpenInit}
        back={toggleInit}
        setOthers={setOthers}
        setData={setData}
      />
    </>
  );
};

export default DataPlanPriceList;

const MakeDataBundle = ({ isOpen, back, datum, others, toggle }) => {
  let {
    dataBundle,
    manageDataBundle,
    nairaSign,
    nairaSignNeutral,
    numberWithCommas,
  } = useContext(GlobalState);
  let init = {
      price: "",
      resellerPrice: "",
      topuserPrice: "",
      provider_price: "",
      agentPrice: "",
      planId: "",
      providerPlanId: "",
      networkId: "",
    },
    [state, setState] = useState(init),
    textChange =
      (name) =>
      ({ target: { value } }) => {
        setState({ ...state, [name]: value });
        console.log(state);
      },
    [newLoad, setNewLoad] = useState(false),
    [submit, setSubmit] = useState(false),
    { step } = useParams();

  useEffect(() => {
    if (datum) {
      setState({ ...state, ...datum });
      let mainAllowance = ["chosen", "rpi", "husmo"]?.includes(
        others?.biller?.billerId
      )
        ? others?.data?.plan
        : others?.data?.size
        ? others?.data?.size
        : others?.data?.allowance;

      let parser = parseFloat(mainAllowance);

      setState({
        ...datum,
        allowance: parser,
        size: mainAllowance
          ?.replace(parser, "")
          ?.replace(".0", "")
          ?.toUpperCase(),
        ringoGLPAY: others?.data?.ringoGLPAY || "false",
      });
      // console.log({ state, datum });
    }

    console.log(state);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datum]);

  let handleSubmit = async (e) => {
    e?.preventDefault();
    if (others?.biller?.billerId !== "chosen" && !state?.price) return;

    if (
      ["chosen", "smeplug", "ogdams", "autosyncng"]?.includes(
        others?.biller?.billerId
      )
    )
      if (
        (others?.biller?.billerId !== "chosen" &&
          !state?.planId &&
          !state?.providerPlanId) ||
        (others?.biller?.billerId !== "chosen" &&
          !state?.networkId &&
          !state?.providerNetworkId) ||
        (others?.biller?.billerId !== "chosen" && !state?.provider_price)
      )
        return;

    setNewLoad(true);
    await manageDataBundle(
      { ...state, dataplan: step },
      `manage-price${datum?._id ? `/${datum?._id}` : ""}`,
      "edit"
    );

    setNewLoad(false);
    setSubmit(true);
  };

  useEffect(() => {
    if (submit && dataBundle?.isUpdated) {
      setSubmit(false);
      setState(init);
      toggle();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submit, dataBundle?.isUpdated]);

  // console.log({ datum, others, state });

  return (
    <>
      <ModalComponents
        title={`${datum?._id ? "update" : "add"} ${others?.biller?.name} price`}
        isOpen={isOpen}
        back={!datum?._id ? back : null}
        toggle={toggle}
      >
        <div className="downH2 d-flex">
          <form className="w-100">
            {!datum?._id ? (
              <div className="mb-4">
                <p className="text-capitalize border-bottom d-flex justify-content-between printOnlyNone">
                  <span>Biller: </span>
                  <span className="fontInherit Lexend text-uppercase">
                    {others?.biller?.name}
                  </span>{" "}
                </p>
                <p className="text-capitalize border-bottom d-flex justify-content-between printOnlyNone">
                  <span>Validity: </span>
                  <span className="fontInherit Lexend text-uppercase">
                    {others?.data?.month_validate
                      ? others?.data?.month_validate
                      : others?.data?.validity
                      ? others?.data?.validity
                      : others?.data?.plan
                      ? others?.data?.plan
                      : others?.data?.name
                      ? others?.data?.name
                      : ""}
                  </span>{" "}
                </p>
                <p className="text-capitalize border-bottom d-flex justify-content-between printOnlyNone">
                  <span>Allowance: </span>
                  <span className="fontInherit Lexend text-uppercase">
                    {["chosen", "rpi"]?.includes(others?.biller?.billerId)
                      ? others?.data?.plan
                      : others?.data?.size
                      ? others?.data?.size
                      : others?.data?.allowance}
                  </span>{" "}
                </p>
                <p className="text-capitalize border-bottom d-flex justify-content-between printOnlyNone">
                  <span>Price: </span>
                  <span className="fontInherit Lexend text-uppercase">
                    {nairaSign}{" "}
                    {numberWithCommas(
                      Number(
                        others?.data?.api_price
                          ? others?.data?.api_price
                          : others?.data?.price
                          ? others?.data?.price
                          : others?.data?.plan_amount
                          ? others?.data?.plan_amount
                          : ""
                      )
                    )}
                  </span>{" "}
                </p>
              </div>
            ) : null}
            <>
              <div className="mb-4">
                <label htmlFor="value">Provider Price</label>

                <NumericFormat
                  prefix={`${nairaSignNeutral} `}
                  className="form-control py-3"
                  value={state.provider_price}
                  placeholder="500"
                  // readOnly
                  displayType="input"
                  thousandSeparator={true}
                  onValueChange={(val) =>
                    setState({ ...state, provider_price: val?.floatValue })
                  }
                  min={0}
                  inputMode="decimal"
                  renderText={(value, props) => <span {...props}>{value}</span>}
                  allowNegative={false}
                />
              </div>
              <div className="mb-4">
                <label htmlFor="value">Price</label>

                <NumericFormat
                  prefix={`${nairaSignNeutral} `}
                  className="form-control py-3"
                  value={state.price}
                  placeholder="500"
                  // readOnly
                  displayType="input"
                  thousandSeparator={true}
                  onValueChange={(val) =>
                    setState({ ...state, price: val?.floatValue })
                  }
                  min={0}
                  inputMode="decimal"
                  renderText={(value, props) => <span {...props}>{value}</span>}
                  allowNegative={false}
                />
              </div>
              <div className="mb-4">
                <label htmlFor="value">Reseller Price</label>
                <NumericFormat
                  prefix={`${nairaSignNeutral} `}
                  className="form-control py-3"
                  value={state.resellerPrice}
                  placeholder="500"
                  // readOnly
                  displayType="input"
                  thousandSeparator={true}
                  onValueChange={(val) =>
                    setState({ ...state, resellerPrice: val?.floatValue })
                  }
                  min={0}
                  inputMode="decimal"
                  renderText={(value, props) => <span {...props}>{value}</span>}
                  allowNegative={false}
                />
              </div>
              <div className="mb-4">
                <label htmlFor="value">Agent Price</label>
                {/* <input
									type={"number"}
									placeholder="200"
									className="form-control py-3"
									value={state?.agentPrice}
									onChange={textChange("agentPrice")}
								/> */}
                <NumericFormat
                  prefix={`${nairaSignNeutral} `}
                  className="form-control py-3"
                  value={state.agentPrice}
                  placeholder="500"
                  // readOnly
                  displayType="input"
                  thousandSeparator={true}
                  onValueChange={(val) =>
                    setState({ ...state, agentPrice: val?.floatValue })
                  }
                  min={0}
                  inputMode="decimal"
                  renderText={(value, props) => <span {...props}>{value}</span>}
                  allowNegative={false}
                />
              </div>
              {["chosen", "smeplug", "ogdams", "autosyncng"]?.includes(
                others?.biller?.billerId
              ) && (
                <>
                  <div className="mb-4">
                    <label htmlFor="value">Product Code/Plan Id</label>
                    <input
                      name={"planId"}
                      type={"text"}
                      placeholder="500"
                      className="form-control py-3"
                      value={state?.planId}
                      onChange={(e) => {
                        setState({ ...state, planId: e?.target?.value });
                      }}
                    />
                    {/* {state.planId ? (
                      <input
                        name={"planId"}
                        type={"text"}
                        placeholder="500"
                        className="form-control py-3"
                        value={state?.planId}
                        onChange={(e) => {
                          setState({ ...state, planId: e?.target?.value });
                        }}
                      />
                    ) : (
                      <input
                        name={"providerPlanId"}
                        type={"text"}
                        placeholder="500"
                        className="form-control py-3"
                        value={state?.providerPlanId}
                        onChange={(e) => {
                          setState({ ...state, providerPlanId: e?.target?.value });
                        }}
                      />
                    )} */}

                    {/* <NumericFormat
                      // prefix={`${nairaSignNeutral} `}
                      className="form-control py-3"
                      value={state.planId || state?.providerPlanId}
                      placeholder="500"
                      displayType="input"
                      // thousandSeparator={true}
                      onValueChange={(val) =>
                        setState({ ...state, planId: val?.value })
                      }
                      min={0}
                      inputMode="tel"
                      renderText={(value, props) => (
                        <span {...props}>{value}</span>
                      )}
                      allowNegative={false}
                      allowLeadingZeros
                    /> */}
                  </div>
                  <div className="mb-4">
                    <label htmlFor="value">Network Id</label>
                    {/* <input
											type={"text"}
											placeholder="Name"
											className="form-control py-3"
											value={state?.networkId || state?.providerNetworkId}
											onChange={textChange("networkId")}
										/> */}
                    <NumericFormat
                      // prefix={`${nairaSignNeutral} `}
                      className="form-control py-3"
                      value={state.networkId || state?.providerNetworkId}
                      placeholder="500"
                      // readOnly
                      displayType="input"
                      // thousandSeparator={true}
                      onValueChange={(val) => {
                        setState({ ...state, networkId: val?.floatValue });
                        console.log(state);
                      }}
                      min={0}
                      inputMode="tel"
                      renderText={(value, props) => (
                        <span {...props}>{value}</span>
                      )}
                      allowNegative={false}
                      allowLeadingZeros
                    />
                  </div>
                </>
              )}
              {/* <div className="mb-4">
								<label htmlFor="value">Topuser price</label>
								<input
									type={"number"}
									placeholder="200"
									className="form-control py-3"
									value={state?.topuserPrice}
									onChange={textChange("topuserPrice")}
								/>
							</div> */}
            </>
            <Buttons
              title={datum?._id ? "update" : "add"}
              css="btn-primary1 text-capitalize py-3 px-4 px-lg-5 mx-auto my-4"
              width={"w-50 w50"}
              onClick={handleSubmit}
              style={{ borderRadius: "30px" }}
              loading={newLoad}
            />
          </form>
        </div>
      </ModalComponents>
    </>
  );
};

const BillerCategorySelection = ({ isOpen, back, setData, setOthers }) => {
  const { biller, category, dataBundle } = useContext(GlobalState);
  let [active, setActive] = useState(0),
    [data, setThisData] = useState({
      biller: "",
    }),
    [selectedBiller, setBiller] = useState(null),
    [selectedCategory, setCategory] = useState(null),
    [dataList, setDataList] = useState(null),
    [selectData, setSelectData] = useState(null),
    { step } = useParams();
  console.log(biller);

  useEffect(() => {
    if (selectedBiller && selectedCategory) {
      let network = selectedCategory?.categoryId?.slice(
        0,
        selectedCategory?.categoryId?.includes("_")
          ? selectedCategory?.categoryId?.lastIndexOf("_")
          : selectedCategory?.categoryId?.length
      );
      let billerName = selectedBiller?.billerId,
        returnData;
      if (
        !["chosen", "rpi", "honourworld"]?.includes(billerName?.toLowerCase())
      ) {
        if (biller?.[billerName])
          returnData = [
            ...biller?.[billerName]?.filter(
              (item) => item?.network === network
            ),
          ];
      } else if (["honourworld"]?.includes(billerName?.toLowerCase())) {
        if (biller?.[billerName])
          returnData = [
            ...biller?.[billerName]?.data?.filter(
              (item) => item?.network === network
            ),
          ];
      } else if (["autosyncng"]?.includes(billerName?.toLowerCase())) {
        if (biller?.[billerName])
          returnData = [
            ...biller?.[billerName]?.filter(
              (item) => item?.network === network
            ),
          ];
      } else {
        if (biller?.[billerName])
          returnData = [
            ...biller?.[billerName]?.data?.[network?.toLowerCase()],
          ];
      }
      setDataList(returnData);
      console.log(dataList);
    }
  }, [selectedBiller, selectedCategory, biller, dataList]);

  useEffect(() => {
    if (selectData) {
      setThisData({
        ...data,
        networkId: selectData?.networkId,
        planId: selectData?.planId,
        provider_price: selectData?.plan_amount
          ? selectData?.plan_amount
          : selectData?.price,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectData]);

  useEffect(() => {
    let DataCat = dataBundle?.data?.find((item) => item?._id === step);

    let categoryNew = category?.data?.find(
      (item) => item?._id === DataCat?.category?._id
    );

    setCategory(categoryNew);
    if (DataCat)
      setThisData({
        ...data,
        price: DataCat?.price,
        topuserPrice: DataCat?.topuserPrice,
        resellerPrice: DataCat?.resellerPrice,
        agentPrice: DataCat?.agentPrice,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step, dataBundle, category]);

  return (
    <ModalComponents
      title={"Add data price process"}
      isOpen={isOpen}
      toggle={back}
    >
      <>
        {active === 1 ? (
          <>
            <BillerSelectPlan
              selectData={selectData}
              selectedBiller={selectedBiller}
              setSelectData={setSelectData}
              dataList={dataList}
            />
            <div className="mx-auto d-block w-50">
              <div className="btn-group">
                <Buttons
                  title={"prev"}
                  css="btn-primary1 text-capitalize py-3 px-4 px-lg-5 mx-auto my-4"
                  width={"w-50 w50"}
                  onClick={() => setActive(0)}
                />
                <Buttons
                  title={"next"}
                  css="btn-primary1 text-capitalize py-3 px-4 px-lg-5 mx-auto my-4"
                  width={"w-50 w50"}
                  onClick={() => {
                    if (
                      !["chosen", "smeplug", "ogdams", "autosyncng"]?.includes(
                        selectedBiller?.billerId
                      )
                    )
                      if (!selectData) return;
                    setData(data);
                    setOthers({
                      category: selectedCategory,
                      biller: selectedBiller,
                      data: selectData,
                    });
                    setActive(0);
                    back();
                  }}
                />
              </div>
            </div>
          </>
        ) : (
          <>
            <h3 className="Lexend">Select Biller</h3>
            <div className="row mx-0 g-3 g-md-4">
              {biller?.data?.map((item, i) => (
                <div
                  className="col-6 col-md-4 p-2"
                  onClick={() => {
                    setThisData({ ...data, biller: item?._id });
                    setBiller(item);
                  }}
                  key={i}
                >
                  <button
                    className={`btn ${
                      item?._id === data?.biller
                        ? "btn-primary1"
                        : "btn-outline-primary1"
                    } w-100 h-100 text-uppercase py-3 py-md-5 rounded20`}
                  >
                    {item?.name}
                  </button>
                </div>
              ))}
            </div>
            <div className="mx-auto d-block w-50">
              <Buttons
                title={"next"}
                css="btn-primary1 text-capitalize py-3 px-4 px-lg-5 mx-auto my-4"
                width={"w-50 w50"}
                onClick={() => {
                  if (!data?.biller) return;
                  setActive(1);
                }}
              />
              {/* </div> */}
            </div>
          </>
        )}
      </>
    </ModalComponents>
  );
};
