import React, { useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
// import { Container } from "reactstrap";
import { GlobalState } from "../../../Data/Context";
import { BsPencil } from "react-icons/bs";
import { RiDeleteBin6Line } from "react-icons/ri";
import { Icon } from "@iconify/react";

const colors = ["#f1c40f", "#fd7e14", "#0d6efd"];

export const AddAndDelete = ({ handleEdit, handleDelete }) => {
  return (
    <div className="tw-flex tw-items-center tw-gap-10">
      {handleEdit && (
        <span onClick={handleEdit} className="tw-text-main tw-opacity-40">
          <BsPencil />
        </span>
      )}
      {handleDelete && (
        <span onClick={handleDelete} className="tw-text-red tw-cursor-pointer">
          <RiDeleteBin6Line />
        </span>
      )}
    </div>
  );
};

const AppSettings = () => {
  let { setStateName } = useContext(GlobalState);
  useEffect(() => {
    setStateName("Manage App Settings");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  let navigate = useNavigate(),
    { page, id } = useParams(),
    controlsTab = [
      {
        name: "Service Icon",
        type: "link",
        link: `/${page}/${id}/serviceicon`,
        color: "linear-gradient(90deg, #DE0DE2 16.14%, #0E102D 101.45%)",
        textColor: "white",
        icon: "hugeicons:power-service",
      },
      {
        name: "Privacy Policy",
        type: "link",
        link: `/${page}/${id}/privacy`,
        color: "linear-gradient(90deg, #F45F83 16.14%, #9E1A2A 101.45%)",
        textColor: "white",
        icon: "material-symbols:privacy-tip-outline-rounded",
      },
      {
        name: "Terms and Condition",
        type: "link",
        link: `/${page}/${id}/terms`,
        color: "linear-gradient(96.86deg, #F2E553 18.88%, #FF9900 125.77%)",
        icon: "carbon:term-reference",
      },
    ];

  return (
    <div className="bg-white aboutScreen tw-py-10">
      <div className="tw-px-5 md:tw-px-10">
        <div className="tw-grid tw-grid-cols-4 tw-w-5/6 tw-mx-auto tw-gap-8">
          {controlsTab?.map((item, i) => (
            <div
              className="tw-bg-white tw-flex tw-justify-between tw-items-center tw-px-3 tw-capitalize tw-shadow-md tw-h-20"
              key={i}
            >
              <div className="">
                <div>
                  <Icon
                    color={colors[i % colors.length]}
                    style={{
                      fontSize: "36px",
                    }}
                    icon={item?.icon}
                  />
                  {/* <img src={item.icon2} alt="" className="tw-h-16" /> */}
                </div>
                {/* <img src={item.icon} alt="" className="tw-h-16" /> */}
              </div>
              <span
                onClick={() => {
                  if (item?.type === "link") {
                    navigate(item?.link);
                  } else if (item?.type === "button") {
                    // if (item?.link === "debit") toggle();
                  }
                }}
                className=""
                // style={{
                //   background: item?.color,
                //   borderRadius: "30px",
                //   color: item?.textColor ? item?.textColor : "#000",
                // }}
              >
                <span className="tw-text-base tw-text-[#1b1b1b] tw-cursor-pointer tw-font-semiboold">
                  {item?.name}
                </span>
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AppSettings;
