import React, { useContext, useEffect, useState } from "react";
// import { Container } from "reactstrap";
import { GlobalState } from "../../Data/Context";
import { Buttons } from "../../Utils";
import {
	AdminDetails,
	MakeAdmin,
	PrivilegeTopper,
} from "../../Components/Users";

const Managers = () => {
	let { setStateName } = useContext(GlobalState);
	useEffect(() => {
		setStateName("Manage Manager");
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	let [isOpen, setIsOpen] = useState(false),
		toggle = () => {
			setIsOpen(!isOpen);
		};

	return (
		<div className="bg-white aboutScreen">
			<div className="py-3 tw-px-5 md:tw-px-10">
				<PrivilegeTopper privilege={"manager"} />
			</div>
			<div className="py-5 tw-px-5 md:tw-px-10">
				<h4>Managers</h4>{" "}
				<div className="btn-group">
					<Buttons
						title={"add new"}
						css="btn-primary1 text-capitalize py-md-3 py-2 px-4 px-lg-5 my-md-4 my-2 mb-0"
						width={"w-auto"}
						onClick={toggle}
						style={{ borderRadius: "30px" }}
					/>
				</div>
				<AdminDetails privilege="manager" />
			</div>{" "}
			<MakeAdmin isOpen={isOpen} back={toggle} privilege="manager" />
		</div>
	);
};

export default Managers;
