import React, { useState, useContext, useEffect } from "react";
// import { Container } from "reactstrap";
import {
  MainPaginate,
  TransactionDetails,
  TransactionsData,
} from "../../../Components/Transactions";
import { GlobalState } from "../../../Data/Context";
import { BottomTab } from "../../../Components/LoadMore";
import { useLocation } from "react-router-dom";

const ReferencingTransactions = () => {
  let { setStateName } = useContext(GlobalState);

  useEffect(() => {
    setStateName("Referencing Transactions");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="bg-white">
      <div className="tw-px-5 md:tw-px-10">
        <ReferencingTransactionsFolder />
      </div>
    </div>
  );
};

export default ReferencingTransactions;

export const ReferencingTransactionsFolder = () => {
  const location = useLocation();
  const { getReload } = useContext(GlobalState);

  let [search, setSearch] = useState(""),
    [state, setState] = useState(null),
    [thisData, setThisData] = useState(null);
  const [pageCount, setPageCount] = useState(1);

  //   useEffect(() => {
  //     if (search) {
  //       document.getElementById("Search").addEventListener("search", () => {
  //         getReload();
  //       });
  //       let handleSubmit = async () => {
  //         if (!search) return;

  //         await getServicesHistoryHeld({
  //           search,
  //         });
  //       };
  //       handleSubmit();
  //     }
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  //   }, [search]);

  useEffect(() => {
    setState(location.state);
  }, [location.state]);

  useEffect(() => {
    getReload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!state) return <></>;

  return (
    <div className="py-5">
      <TransactionsData
        state={location.state}
        setThisData={setThisData}
        setState={setState}
        type={"all"}
        mainState={state}
      />

      <TransactionDetails
        type={"all"}
        thisData={thisData}
        setThisData={setThisData}
      />
    </div>
  );
};
