import React, { useEffect, useContext } from "react";
import { BonusCommission } from "../../../Components/Wallets";
// import { Container } from "reactstrap";
import { GlobalState } from "../../../Data/Context";

const ManualCommission = () => {
  let { setStateName } = useContext(GlobalState);

  useEffect(() => {
    setStateName("Manual Commission");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="bg-white aboutScreen">
      <div className="py-3 py-md-5 tw-px-5 md:tw-px-10">
        <h5 className="Lexend text-capitalize">Manual Commission</h5>
        <BonusCommission general={"authorizer"} />
      </div>{" "}
    </div>
  );
};

export default ManualCommission;
